import React from "react";
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';

// Import your Dashboard component
import Dashboard from './Dashboard';

const AppAds = () => {
  return (
    <div>
      <pre>
        google.com, pub-3188753354228120, DIRECT, f08c47fec0942fa0
        pubnative.net, 1007040, RESELLER, d641df8625486a7b
        pubnative.net, 1007974, RESELLER, d641df8625486a7b
        pubnative.net, 1008379, RESELLER, d641df8625486a7b
        pubnative.net, 1009988, RESELLER, d641df8625486a7b
        pubnative.net, 1006955, RESELLER, d641df8625486a7b
        smartclip.net, 27985, RESELLER
        Media.net, 8CU12AQ1Y, RESELLER
        blueseasx.com, 232670, RESELLER, 7998eac5087f6110
        inmobi.com, 0c8e054083954c68a45044396a1c3df8, RESELLER, 83e75a7ae333ca9d
        inmobi.com, 791b84bdd791470faa8dca5f04e6a83b, RESELLER, 83e75a7ae333ca9d
        inmobi.com, b01aa06531c543d8a5fb9982f60afb00, RESELLER, 83e75a7ae333ca9d
        inmobi.com, edd282ac8f29464792bf2b7f3df2f9df, RESELLER, 83e75a7ae333ca9d
        onlinemediasolutions.com, 23120, RESELLER
        betweendigital.com, 45827, RESELLER
        betweendigital.com, 45329, RESELLER
        toponad.com, 165535463cfb70, RESELLER, 1d49fe424a1a456d
        zetaglobal.net, 815, RESELLER
        openx.com, 540857594, RESELLER, 6a698e2ec38604c6
        openx.com, 540393169, RESELLER, 6a698e2ec38604c6
        openx.com, 540861995, RESELLER, 6a698e2ec38604c6
        openx.com, 540871654, RESELLER, 6a698e2ec38604c6
        openx.com, 540679900, RESELLER, 6a698e2ec38604c6
        openx.com, 541017750, RESELLER, 6a698e2ec38604c6
        rubiconproject.com, 15854, RESELLER, 0bfd66d529a55807
        rubiconproject.com, 14558, RESELLER, 0bfd66d529a55807
        rubiconproject.com, 18202, RESELLER, 0bfd66d529a55807
        rubiconproject.com, 26270, RESELLER, 0bfd66d529a55807
        rubiconproject.com, 25336, RESELLER, 0bfd66d529a55807
        rubiconproject.com, 26292, RESELLER, 0bfd66d529a55807
        rubiconproject.com, 26156, RESELLER, 0bfd66d529a55807
        rubiconproject.com, 26132, RESELLER, 0bfd66d529a55807
        rubiconproject.com, 24170, RESELLER, 0bfd66d529a55807
        rubiconproject.com, 20744, RESELLER, 0bfd66d529a55807
        rubiconproject.com, 20014, RESELLER, 0bfd66d529a55807
        rubiconproject.com, 15268, RESELLER, 0bfd66d529a55807
        rubiconproject.com, 22134, RESELLER, 0bfd66d529a55807
        rubiconproject.com, 25978, RESELLER, 0bfd66d529a55807
        rubiconproject.com, 24752, RESELLER, 0bfd66d529a55807
        rubiconproject.com, 26250, RESELLER, 0bfd66d529a55807
        rubiconproject.com, 24400, RESELLER, 0bfd66d529a55807
        rubiconproject.com, 25872, RESELLER, 0bfd66d529a55807
        rubiconproject.com, 24362, RESELLER, 0bfd66d529a55807
        rubiconproject.com, 17608, RESELLER, 0bfd66d529a55807
        rubiconproject.com, 26958, RESELLER, 0bfd66d529a55807
        rubiconproject.com, 17130, RESELLER, 0bfd66d529a55807
        rubiconproject.com, 26158, RESELLER, 0bfd66d529a55807
        rubiconproject.com, 17960, RESELLER, 0bfd66d529a55807
        smartadserver.com, 4610, RESELLER, 060d053dcf45cbf3
        smartadserver.com, 4568, RESELLER, 060d053dcf45cbf3
        smartadserver.com, 3172, RESELLER, 060d053dcf45cbf3
        smartadserver.com, 4467, RESELLER
        triplelift.com, 12908, RESELLER, 6c33edb13117fd86
        triplelift.com, 13883, RESELLER, 6c33edb13117fd86
        triplelift.com, 12158, RESELLER, 6c33edb13117fd86
        zmaticoo.com, 5135704, RESELLER
        zmaticoo.com, 5135195, RESELLER
        krushmedia.com, ajxf6R585a9m6Catvk, RESELLER
        pokkt.com, 6246, RESELLER, c45702d9311e25fd
        risecodes.com, 6486c6155b231000010244b0, RESELLER
        vidazoo.com, 66e04f5e4d7fd89d878d29d2, RESELLER, b6ada874b4d7d0b2
        lacunads.com, lcb555b3bc8667a09a, RESELLER
        lijit.com, 503707, RESELLER, fafdf38b16bf6b2b
        lijit.com, 511365, RESELLER, fafdf38b16bf6b2b
        lijit.com, 491737, RESELLER, fafdf38b16bf6b2b
        lijit.com, 546566, RESELLER, fafdf38b16bf6b2b
        lijit.com, 481366, RESELLER, fafdf38b16bf6b2b
        lijit.com, 483304, RESELLER, fafdf38b16bf6b2b
        lijit.com, 489963, RESELLER, fafdf38b16bf6b2b
        lijit.com, 465542, RESELLER, fafdf38b16bf6b2b
        lijit.com, 417620, RESELLER, fafdf38b16bf6b2b
        outbrain.com, 0023749a2264ea0429a71b54ac9ca0de9a, RESELLER
        outbrain.com, 00ab8f68679cc060c8bbc1035e70030614, RESELLER
        sharethrough.com, 6qlnf8SY, RESELLER, d53b998a7bd4ecd2
        sharethrough.com, K5WDGAsP, RESELLER, d53b998a7bd4ecd2
        sharethrough.com, bXzzb1bR, RESELLER, d53b998a7bd4ecd2
        sharethrough.com, XeKuhSkz, RESELLER, d53b998a7bd4ecd2
        appads.in, 106246, RESELLER
        contextweb.com, 562791, RESELLER, 89ff185a4c4e857c
        contextweb.com, 563217, RESELLER, 89ff185a4c4e857c
        contextweb.com, 563265, RESELLER, 89ff185a4c4e857c
        contextweb.com, 562827, RESELLER, 89ff185a4c4e857c
        improvedigital.com, 2276, RESELLER
        improvedigital.com, 2464, RESELLER
        improvedigital.com, 2525, RESELLER
        unity.com, 5796289, DIRECT, 96cabb5fbdde37a7
        voisetech.com, 1066, RESELLER
        yeahmobi.com, 5135195, RESELLER
        taurusx.com, 80029, RESELLER
        thunder-monetize.com, 5257091605, RESELLER
        thunder-monetize.com, 8221217003, RESELLER
        admanmedia.com, 990, RESELLER
        admanmedia.com, 2153, RESELLER
        admanmedia.com, 2242, RESELLER
        e-planning.net, 79686787743ddfc5, RESELLER, c1ba615865ed87b2
        google.com, pub-3990748024667386, RESELLER, f08c47fec0942fa0
        ignitemediatech.com, pub_11115, RESELLER
        xandr.com, 7353, RESELLER
        xandr.com, 15769, RESELLER
        xandr.com, 13799, RESELLER
        adform.com, 716, RESELLER
        bid-matrix.com, f2c71a45ea149366, RESELLER
        connekt.ai, 1732945743, RESELLER
        playwire.com, 1018531, RESELLER
        playwire.com, 1025119, RESELLER
        pubwise.io, 22376824, RESELLER, c327c91a93a7cdd3
        webeyemob.com, 80029, RESELLER
        loopme.com, 11463, RESELLER, 6c8d5f95897a5a3b
        loopme.com, 11635, RESELLER, 6c8d5f95897a5a3b
        loopme.com, 9621, RESELLER, 6c8d5f95897a5a3b
        orangeclickmedia.com, c-1181, RESELLER
        verve.com, 15503, RESELLER, 0c8f5958fc2d6270
        xapads.com, 117219, RESELLER
        pubmatic.com, 158060, RESELLER, 5d62403b186f2ace
        pubmatic.com, 158481, RESELLER, 5d62403b186f2ace
        pubmatic.com, 159668, RESELLER, 5d62403b186f2ace
        pubmatic.com, 164601, RESELLER, 5d62403b186f2ace
        pubmatic.com, 161151, RESELLER, 5d62403b186f2ace
        pubmatic.com, 162882, RESELLER, 5d62403b186f2ace
        pubmatic.com, 156520, RESELLER, 5d62403b186f2ace
        pubmatic.com, 160493, RESELLER, 5d62403b186f2ace
        pubmatic.com, 158154, RESELLER, 5d62403b186f2ace
        pubmatic.com, 165116, RESELLER, 5d62403b186f2ace
        pubmatic.com, 164119, RESELLER, 5d62403b186f2ace
        pubmatic.com, 160456, RESELLER, 5d62403b186f2ace
        pubmatic.com, 162270, RESELLER, 5d62403b186f2ace
        pubmatic.com, 162588, RESELLER, 5d62403b186f2ace
        pubmatic.com, 165438, RESELLER, 5d62403b186f2ace
        pubmatic.com, 164532, RESELLER, 5d62403b186f2ace
        pubmatic.com, 162968, RESELLER, 5d62403b186f2ace
        pubmatic.com, 159906, RESELLER, 5d62403b186f2ace
        pubmatic.com, 163319, RESELLER, 5d62403b186f2ace
        pubmatic.com, 159501, RESELLER, 5d62403b186f2ace
        video.unrulymedia.com, 585054906, RESELLER
        video.unrulymedia.com, 2464975885, RESELLER
        video.unrulymedia.com, 6111915185540403805, RESELLER
        video.unrulymedia.com, 123476257, RESELLER
        video.unrulymedia.com, 2743945877, RESELLER
        video.unrulymedia.com, 848837292, RESELLER
        video.unrulymedia.com, 436487936, RESELLER
        video.unrulymedia.com, 324806554, RESELLER, a670c89d4a324e47
        video.unrulymedia.com, 158727213, RESELLER
        video.unrulymedia.com, 755432070, RESELLER
        video.unrulymedia.com, 1058378980, RESELLER
        video.unrulymedia.com, 498216989, RESELLER
        appnexus.com, 12878, RESELLER, f5ab79cb980f11d1
        appnexus.com, 15629, RESELLER
        appnexus.com, 9382, RESELLER, f5ab79cb980f11d1
        appnexus.com, 15349, RESELLER, f5ab79cb980f11d1
        eliteappgrade.com, 494223, RESELLER
        mars.media, 1010442, RESELLER, 8624339f102fb076
        playdigo.com, 1965, RESELLER, 92011346d63d3c30
        freewheel.tv, 741650, RESELLER
        prado.co, 14260, RESELLER
        visiblemeasures.com, 1020, RESELLER
        opera.com, pub6794361378752, RESELLER, 55a0c5fd61378de3
        videoheroes.tv, 212428, RESELLER, 064bc410192443d8
        videoheroes.tv, 212499, RESELLER, 064bc410192443d8
        conversantmedia.com, 100396, RESELLER, 03113cd04947736d
        conversantmedia.com, 100358, RESELLER, 03113cd04947736d
        conversantmedia.com, 100863, RESELLER, 03113cd04947736d
        eskimi.com, 2020000663, RESELLER
        eskimi.com, 2020000545, RESELLER
        lunamedia.io, 2fdb16a1-5025-4c41-9b17-e01e47ebc8b4, RESELLER, 524ecb396915caaf
        algorix.co, 54616, RESELLER, 5b394c12fea27a1d
        ushareit.com, lcb555b3bc8667a09a, RESELLER
        kidoz.net, 14260, RESELLER, a109366414b7335e
        onetag.com, 8668c16092f01e8, RESELLER
        onetag.com, 5d1628750185ace, RESELLER
        onetag.com, 8c90176af2e65c8, RESELLER
        rhebus.works, 5000863388, RESELLER
        castify.ai, 1295184, RESELLER
        thebrave.io, 1234634, RESELLER, c25b2154543746ac
        tradplusad.com, b3fca24bd11c1242, RESELLER
      </pre>
    </div>
  );
};

const App = () => {
  return (
    <Router>
      <Routes>
        <Route path="/app-ads.txt" element={<AppAds />} />
        <Route path="/*" element={<Dashboard />} />
      </Routes>
    </Router>
  );
};

export default App;
